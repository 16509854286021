/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  // api: 'https://chat-api-new-pp.contactdve.com/api',
  // chat: 'https://chat-liveengine.contactdve.com/',
  // chat: 'localhost:3000',
  extCode: 'FR',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.fr/news',
  // newsSectionUrl: null,
  footerText: null,
  footerLinks: [
    { value: 'CGU', href: '//m.weezchat.fr/cgu' },
    { value: 'Mentions légales', href: '//m.weezchat.fr/infoediteur' },
    { value: 'Conditions tarifaires', href: '//m.weezchat.fr/abonnement' },
    { value: 'Données personnelles', href: '//m.weezchat.fr/donneespersonnelles' },
    { value: 'Cookies', href: '//m.weezchat.fr/cookies' },
    { value: 'Se désabonner', href: '//m.weezchat.fr/desabo' },
    { value: 'Nous contacter', href: '//m.weezchat.fr/infoediteur' },
    { value: 'Mon compte', href: '//m.weezchat.fr/moncompte' },
  ],
  LP: 'https://promo.weezchat.fr/pwzchat/lp_weetic_wall/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 1200,
  gtm: 'GTM-WQ4P2S',
  showAllCities: false,
  completeProfileInputs: ['email'],
  RTL: false,
  unsubscribeLink: '//m.weezchat.fr/desabo',
freeSubscription : false,
  home: {
    app: {
      available: true,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.tchat.weezchat&hl=fr ',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/Weezchat/'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/WeezchatFr'
    },
    instagram: {
      available: true,
      link: 'https://www.instagram.com/weezchat_fr/'
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://m.weezchat.fr/cookies'
    }
  }
};
